<template>
  <div>
    <div v-if="dataLoaded">
      <v-container class="pa-0">
        <!--app-view-toolbar-->
        <app-view-toolbar :title="headline" :showBackButton="true" :buttons="toolbarButtons" @onButton="onToolbarButton"></app-view-toolbar>
      </v-container>
      <v-container class="py-2 responsive-container-class">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-alert
            prominent
            type="error"
            v-if="alertMessage.length"
          >
            <v-row align="center">
              <v-col class="grow">
                <div class="mb-1"><strong>{{ alertHeadline}}</strong></div>
                <div v-html="alertMessage"></div>
              </v-col>
              <v-col class="shrink">
                <v-btn @click="resetAlert">Ok</v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <div>
            <div class="text-button">Kund</div>
            <app-auto-complete
              :label="customerSearchConfig.label"
              :apiEndPoint="customerSearchConfig.apiEndPoint"
              :modelValue="selectedCustomer"
              :txtLabel="customerSearchConfig.txtLabel"
              @onSelection="onCustomerSelect"
              @onClear="onCustomerClear"
              mode="single"
            ></app-auto-complete>
          </div>
          <v-expansion-panels
            class="mb-8 vajper-v-expansion"
            multiple
            :value="initiallyOpenedExpansionPanels"
            flat
          >
            <v-expansion-panel class="grey">
              <v-expansion-panel-header>Addressuppgifter</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="6">
                    <div class="text-subtitle-2 mb-4">Fakturaadress</div>
                    <v-text-field
                      v-for="field of invoiceFields"
                      :key="field.uohField"
                      :label="field.label"
                      v-model="viewData.uoh[field.uohField]"
                      :placeholder="viewData.uoh[field.placeholderField]"
                      persistent-placeholder
                      dense
                      outlined
                      background-color="white"
                      @focus="onFieldFocus(field.uohField, viewData.uoh[field.uohField])"
                      @blur="onFieldEdit('uoh', field.uohField, viewData.uoh)"
                    >
                    </v-text-field>
                    <v-select
                      label="Land"
                      v-model="viewData.uoh.uoh_id_cnt_invoice"
                      :placeholder="viewData.uoh.customer_invCountry"
                      persistent-placeholder
                      dense
                      outlined
                      :items="countries"
                      background-color="white"
                      @focus="onFieldFocus('uoh_id_cnt_invoice', viewData.uoh.uoh_id_cnt_invoice)"
                      @change="onFieldEdit('uoh', 'uoh_id_cnt_invoice', viewData.uoh, true)"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <div class="text-subtitle-2 mb-4">Leveransadress</div>
                    <v-text-field
                      v-for="field of shippingFields"
                      :key="field.uohField"
                      :label="field.label"
                      v-model="viewData.uoh[field.uohField]"
                      :placeholder="viewData.uoh[field.placeholderField]"
                      persistent-placeholder
                      dense
                      outlined
                      background-color="white"
                      @focus="onFieldFocus(field.uohField, viewData.uoh[field.uohField])"
                      @blur="onFieldEdit('uoh', field.uohField, viewData.uoh)"
                    >
                    </v-text-field>
                    <v-select
                      label="Land"
                      v-model="viewData.uoh.uoh_id_cnt_shipping"
                      :placeholder="viewData.uoh.customer_shpCountry"
                      persistent-placeholder
                      dense
                      outlined
                      :items="countries"
                      background-color="white"
                      @focus="onFieldFocus('uoh_id_cnt_shipping', viewData.uoh.uoh_id_cnt_shipping)"
                      @change="onFieldEdit('uoh', 'uoh_id_cnt_shipping', viewData.uoh, true)"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="grey">
              <v-expansion-panel-header>Orderrader</v-expansion-panel-header>
              <v-expansion-panel-content>
                <app-order-rows
                  mode="new"
                  @onProductSelect="onProductSelect"
                  @onProductBarcodeScan="onProductBarcodeScan"
                  @addOrderRowText="addUorText"
                  @onOrderRowDrag="onUorDrag"
                  @onFieldEdit="onFieldEdit"
                  @onFieldFocus="onFieldFocus"
                  @removeOrderRow="removeOrderRow"
                  :searchableProduct_items="searchableProduct_items"
                  :searchableProduct_labelCols="searchableProduct_labelCols"
                  :orderRows="viewData.uors"
                  :orderRowIdToFocus="orderRowIdToFocus"
                  :priceTypeIsNetto="priceTypeIsNetto"
                >
                </app-order-rows>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="grey">
              <v-expansion-panel-header>Orderval</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mb-2" v-if="hasUoh">
                  <v-col cols="12" md="4">
                    <div class="text-button mb-2">Övrigt</div>
                    <v-combobox
                      v-model="viewData.uoh.uoh_str_our_reference"
                      :items="ourReferences"
                      label="Vår referens"
                      dense
                      outlined
                      maxlength="50"
                      background-color="white"
                      :rules="[ rules.requiredCombobox ]"
                      @focus="onFieldFocus('uoh_str_our_reference', viewData.uoh.uoh_str_our_reference)"
                      @blur="onFieldEdit('uoh', 'uoh_str_our_reference', viewData.uoh, true)"
                    >
                    </v-combobox>
                    <v-text-field
                      v-model="viewData.uoh.uoh_dec_shipping_amount"
                      label="Fraktkostnad inkl. moms"
                      dense
                      outlined
                      suffix="kr"
                      maxlength="9"
                      background-color="white"
                      @focus="onFieldFocus('uoh_dec_shipping_amount', viewData.uoh.uoh_dec_shipping_amount)"
                      @blur="onFieldEdit('uoh', 'uoh_dec_shipping_amount', viewData.uoh)"
                      @keyup="viewData.uoh.uoh_dec_shipping_amount = restoreNumeric(viewData.uoh.uoh_dec_shipping_amount)"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="viewData.uoh.uoh_str_reference"
                      label="Ordermärkning"
                      dense
                      outlined
                      maxlength="50"
                      background-color="white"
                      @focus="onFieldFocus('uoh_str_reference', viewData.uoh.uoh_str_reference)"
                      @blur="onFieldEdit('uoh', 'uoh_str_reference', viewData.uoh)"
                    >
                    </v-text-field>
                    <v-textarea
                      v-model="viewData.uoh.uoh_str_customer_comment"
                      label="Kundkommentar - publikt synlig för kund"
                      dense
                      outlined
                      rows="3"
                      background-color="white"
                      @focus="onFieldFocus('uoh_str_customer_comment', viewData.uoh.uoh_str_customer_comment)"
                      @blur="onFieldEdit('uoh', 'uoh_str_customer_comment', viewData.uoh)"
                    >
                    </v-textarea>
                    <v-textarea
                      v-model="viewData.uoh.uoh_str_comment"
                      label="Intern kommentar"
                      dense
                      outlined
                      rows="3"
                      background-color="#FEE"
                      @focus="onFieldFocus('uoh_str_comment', viewData.uoh.uoh_str_comment)"
                      @blur="onFieldEdit('uoh', 'uoh_str_comment', viewData.uoh)"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div class="text-button mb-2">Kontaktuppgifter</div>
                    <v-combobox
                      v-model="viewData.uoh.uoh_str_your_reference"
                      :items="yourReferences"
                      label="Er referens"
                      dense
                      outlined
                      maxlength="50"
                      background-color="white"
                      @focus="onFieldFocus('uoh_str_your_reference', viewData.uoh.uoh_str_your_reference)"
                      @blur="onFieldEdit('uoh', 'uoh_str_your_reference', viewData.uoh, true)"
                    >
                    </v-combobox>
                    <v-text-field
                      v-for="field of contactFields"
                      :key="field.uohField"
                      :label="field.label"
                      v-model="viewData.uoh[field.uohField]"
                      :placeholder="viewData.uoh[field.placeholderField]"
                      persistent-placeholder
                      dense
                      outlined
                      background-color="white"
                      @focus="onFieldFocus(field.uohField, viewData.uoh[field.uohField])"
                      @blur="onFieldEdit('uoh', field.uohField, viewData.uoh)"
                      :rules="field.rules"
                      :disabled="field.disableIfPlaceholder && !!viewData.uoh[field.placeholderField]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <template v-if="stockPoints.length">
                      <div class="text-button">Lagerställe</div>
                      <v-chip-group
                        active-class="primary--text"
                        v-model="viewData.uoh.uoh_id_spn"
                        @change="onFieldEdit('uoh', 'uoh_id_spn', viewData.uoh, true)"
                        column
                        class="mb-2"
                      >
                        <v-chip
                          v-for="stockPoint in stockPoints"
                          :key="stockPoint.spn_id"
                          :value="stockPoint.spn_id"
                        >
                          {{ stockPoint.spn_str_name }}
                        </v-chip>
                      </v-chip-group>
                    </template>
                    <template v-if="hasModule_fortnoxNodeJsTransferOrder">
                      <div class="text-button">Bokföringsmetod</div>
                      <v-chip-group
                        active-class="primary--text"
                        v-model="viewData.uoh.uoh_enm_accounting_method"
                        @change="onFieldEdit('uoh', 'uoh_enm_accounting_method', viewData.uoh, true)"
                        column
                        class="mb-2"
                      >
                        <v-chip
                          v-for="accountingMethod in accountingMethods"
                          :key="accountingMethod.value"
                          :value="accountingMethod.value"
                        >
                          {{ accountingMethod.label }}
                        </v-chip>
                      </v-chip-group>
                    </template>
                    <div class="text-button">Pristyp</div>
                    <v-chip-group
                      mandatory
                      active-class="primary--text"
                      v-model="viewData.uoh.uoh_enm_price_type"
                      @change="onFieldEdit('uoh', 'uoh_enm_price_type', viewData.uoh, true)"
                      column
                      class="mb-2"
                    >
                      <v-chip
                        v-for="priceType in priceTypes"
                        :key="priceType.value"
                        :value="priceType.value"
                      >
                        {{ priceType.label }}
                      </v-chip>
                    </v-chip-group>
                    <!-- <v-btn-toggle
                      v-model="viewData.uoh.uoh_enm_price_type"
                      class="xDenseToggle mb-4"
                      color="blue darken-4"
                      mandatory
                      @change="onFieldEdit('uoh', 'uoh_enm_price_type', viewData.uoh, true)"
                    >
                      <v-btn
                        v-for="nettoType in priceTypes"
                        :key="nettoType.value"
                        :value="nettoType.value"
                        text
                        small
                      >
                        {{ nettoType.label }}
                      </v-btn>
                    </v-btn-toggle> -->
                    <div class="text-button">Leveranssätt</div>
                    <v-chip-group
                      mandatory
                      active-class="primary--text"
                      v-model="viewData.uoh.uoh_id_dmt"
                      @change="onFieldEdit('uoh', 'uoh_id_dmt', viewData.uoh, true)"
                      column
                      class="mb-2"
                    >
                      <v-chip
                        v-for="deliveryMethod of deliveryMethods"
                        :key="deliveryMethod.value"
                        :value="deliveryMethod.value"
                      >
                        {{ deliveryMethod.text }}
                      </v-chip>
                    </v-chip-group>
                    <v-switch
                      v-model="viewData.uoh.uoh_bit_send_order_recognition"
                      @change="onFieldEdit('uoh', 'uoh_bit_send_order_recognition', viewData.uoh, true)"
                      label="Skicka ordererkännande"
                      class="mt-0"
                    ></v-switch>
                    <v-switch
                      v-if="isKaxig"
                      v-model="avoidCreateFortnoxOrder"
                      label="Undvik skapa order i Fortnox"
                      class="mt-0"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
        <!-- <v-btn @click="showDebug()">Debug</v-btn> -->
      </v-container>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from '@vue/composition-api'
import useCore from '@/helpers/core'
import useValidation from '@/helpers/validation'
import { appBus } from '@/main'
// import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n'
import AutoComplete from '@/components/AutoComplete.vue'
import OrderRows from '@/components/OrderRows.vue'
import ViewToolbar from '@/components/ViewToolbar.vue'

export default {
  name: 'OrderPut',
  components: {
    'app-auto-complete': AutoComplete,
    'app-view-toolbar': ViewToolbar,
    'app-order-rows': OrderRows,
  },
  setup() {
    // Vue 2
    const store = require('@/store/store').default;
    const router = require('@/router/router').default;
    const loggedInData = computed(() => store.getters.loggedInData);

    const isKaxig = loggedInData.value.isKaxig;

    const { callApi, showMessage, closeMessage, tellKobra, parseMessages, convertJsonToHtml, delay } = useCore();
    const { rules, restoreNumeric } = useValidation();

    const doProfileAutoCompleteFocus = ref(false);

    const headline = 'Ny order';

    const customerId = ref(0);
    const cgrId = ref(0);
    const countries = ref([]);
    const yourReferences = ref([]);
    const ourReferences = ref([]);
    const deliveryMethods = ref([]);
    const stockPoints = ref([]);
    const selectedCustomer = ref(null);
    const focusedFieldValue = ref('');
    const form = ref(null);
    const alertHeadline = ref('');
    const alertMessage = ref('');
    const orderRowIdToFocus = ref(0);


    const saveInProgress = ref(false);
    const avoidCreateFortnoxOrder = ref(false);
    const initiallyOpenedExpansionPanels = ref([1,2]);

    const viewData = reactive({
      uoh: null,
      uors: [],
    });

    const searchableProduct_labelCols = ref([]);
    const searchableProduct_items = ref([]);

    const customerSearchConfig = ref(null);

    const hasUoh = computed(() => {
      return !!viewData.uoh;
    });

    const toolbarButtons = computed(() => {
      const buttons = [];
      if (dataLoaded.value) {
        buttons.push({
          label: 'put-order',
          icon: 'check',
          name: 'Lägg order',
          disabled: saveInProgress.value || !viewData.uoh || !viewData.uors || !viewData.uors.length
        });
      }
      return buttons;
    });

    const priceTypes = [
      {
        label: 'Inkl. moms',
        value: 'brutto',
      },
      {
        label: 'Exkl. moms',
        value: 'netto',
      },
      // {
      //   label: 'Inpriser',
      //   value: 'inPrice',
      // },
    ];

    const accountingMethods = [
      {
        label: 'Faktura',
        value: 'accrual',
      },
      {
        label: 'Kontant',
        value: 'cash',
      },
      // {
      //   label: 'Inpriser',
      //   value: 'inPrice',
      // },
    ];

    const invoiceFields = [
      {
        label: 'Företag',
        uohField: 'uoh_str_invoice_company',
        placeholderField: 'customer_invCompany'
      },
      {
        label: 'Förnamn',
        uohField: 'uoh_str_invoice_firstname',
        placeholderField: 'customer_invFirstname'
      },
      {
        label: 'Efternamn',
        uohField: 'uoh_str_invoice_lastname',
        placeholderField: 'customer_invLastname'
      },
      {
        label: 'C/O',
        uohField: 'uoh_str_invoice_care_of',
        placeholderField: 'customer_invCareOf'
      },
      {
        label: 'Gatuadress',
        uohField: 'uoh_str_invoice_street',
        placeholderField: 'customer_invStreet'
      },
      {
        label: 'Post nr',
        uohField: 'uoh_str_invoice_zip',
        placeholderField: 'customer_invZip'
      },
      {
        label: 'Postort',
        uohField: 'uoh_str_invoice_city',
        placeholderField: 'customer_invCity'
      },
    ];

    const shippingFields = [
      {
        label: 'Företag',
        uohField: 'uoh_str_shipping_company',
        placeholderField: 'customer_shpCompany'
      },
      {
        label: 'Förnamn',
        uohField: 'uoh_str_shipping_firstname',
        placeholderField: 'customer_shpFirstname'
      },
      {
        label: 'Efternamn',
        uohField: 'uoh_str_shipping_lastname',
        placeholderField: 'customer_shpLastname'
      },
      {
        label: 'C/O',
        uohField: 'uoh_str_shipping_care_of',
        placeholderField: 'customer_shpCareOf'
      },
      {
        label: 'Gatuadress',
        uohField: 'uoh_str_shipping_street',
        placeholderField: 'customer_shpStreet'
      },
      {
        label: 'Post nr',
        uohField: 'uoh_str_shipping_zip',
        placeholderField: 'customer_shpZip'
      },
      {
        label: 'Postort',
        uohField: 'uoh_str_shipping_city',
        placeholderField: 'customer_shpCity'
      },
    ];

    const dataLoaded = ref(false);

    // const store = useStore();

    // const { t } = useI18n({
    //   messages: {
    //     sv: {
    //       'Search product': 'Sök produkt',
    //       'Product name': 'Benämning',
    //       'Add item': 'Lägg till',
    //       'VAT': 'Moms',
    //       'No hits': 'Inga träffar',
    //     },
    //   },
    // });

    const hasModule_fortnoxNodeJsTransferOrder = computed(() => {
      return store.getters.hasKobraModule('fortnoxNodeJsTransferOrder');
    });

    const contactFields = [
      {
        label: 'E-post',
        uohField: 'uoh_str_email',
        placeholderField: 'customer_email',
        rules: [ rules.email ],
      },
      {
        label: 'Mobil',
        uohField: 'uoh_str_phone',
        placeholderField: 'customer_phone',
        rules: [],
      },
      {
        label: 'Organisationsnummer',
        uohField: 'uoh_str_invoice_vat_no',
        placeholderField: 'customer_invVatNo',
        rules: [],
        disableIfPlaceholder: true,
      },
      {
        label: hasModule_fortnoxNodeJsTransferOrder.value ? 'Fortnox kundnummer' : 'Kundnummer',
        uohField: 'uoh_str_customer_number',
        placeholderField: 'cst_str_customer_number',
        rules: [],
        disableIfPlaceholder: true,
      },
    ];
    
    const uohId = computed(() => {
      // always read uohId form viewData.uoh
      return viewData.uoh.uoh_id || 0;
    });

    
    const priceTypeIsNetto = computed(() => {
      return viewData.uoh.uoh_enm_price_type == 'netto';
    });

    const onProductBarcodeScan = (barcode) => {
      //add by ean
      const apiData = {
        uor: {
          ean: barcode,
        }
      }
      saveUnconcludedOrder(apiData);
    }
    
    const onProductSelect = product => {
      //add by prdId
      const apiData = {
        uor: {
          prdId: product.v,
        }
      }
      saveUnconcludedOrder(apiData);
    }

    const onToolbarButton = button => {
      switch (button.label) {
        case 'put-order':
          requestToPutOrder();
        break;
      }
    }

    const onCustomerSelect = customer => {
      const apiData = {
        customer: {
          id: customer.v,
          mode: customerSearchConfig.label,
        }
      }
      // save in api
      saveUnconcludedOrder(apiData);
    }

    const onCustomerClear = () => {
      cgrId.value = 0;
      const apiData = {
        customer: {
          id: 0,
          mode: customerSearchConfig.label,
        }
      }
      // save in api
      saveUnconcludedOrder(apiData);
    }

    const setOverlay = (show = false, message = null) => {
      if (show) {
        saveInProgress.value = true;
        appBus.$emit('overlay', { message });
      } else {
        saveInProgress.value = false;
        appBus.$emit('overlay');
      }
    }

    const removeOrderRow = uor => {
      // remove client side
      viewData.uors.splice(viewData.uors.indexOf(uor), 1);

      setOverlay(true, 'Tar bort rad från ordern');

      callApi({
        method: 'delete',
        path: '/cms/orders/unconcluded-order-row/' + uor.row_id,
      })
        .then(() => {
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          console.error(err);
        });
    }

    const saveUnconcludedOrder = (apiData = {}) => {
      // complement apiData with common data
      apiData.uohId = uohId.value;
      apiData.customerId = customerId.value;
      // TODO: in order to support another cgrId than the one tied to the customerId, build a cgrId select :)
      apiData.cgrId = cgrId.value;

      if (apiData.uohId == 0) {
        // when new uoh, append some stuff
        apiData = complementWithMandatoryNewUohData(apiData);
      }

      setOverlay(true, 'Sparar');

      callApi({
        method: 'post',
        path: '/cms/orders/unconcluded-order',
        data: apiData,
        doLog: false,
      })
        .then(apiResponse => {
          setOverlay();
          onData(apiResponse);
        })
        .catch((err) => {
          setOverlay();
          console.error(err);
          // showMessage(err);
        });
    }

    const readFieldValue = value => {
      let fieldValue = value ?? '';
      switch (typeof fieldValue) {
        case 'object':
          // combox uses text property
          fieldValue = fieldValue.text;
          break;
        default:
      }
      return fieldValue;
    }

    const complementWithMandatoryNewUohData = data => {
      data.ourReference = viewData.uoh.uoh_str_our_reference;
      data.accountingMethod = viewData.uoh.uoh_enm_accounting_method;
      data.spnId = viewData.uoh.uoh_id_spn;
      return data;
    }

    const onFieldEdit = async (mode, label, data, alwaysUpdate = false) => {
      const currentFieldValue = focusedFieldValue.value;
      // read value - we need to "sleep" for a while before we reading value
      await delay(150);
      const value = readFieldValue(data[label]);
      if (!alwaysUpdate) {
        // only save if input has changed
        const fieldWasChanged = currentFieldValue != value;
        if (!fieldWasChanged) {
          return;
        }
      }
      let apiData;


      switch (mode) {
        case 'uoh':
          apiData = {
            uohId: uohId.value,
            fields: [
              {
                field: label,
                value,
              }
            ]
          }

          if (apiData.uohId == 0) {
            // when new uoh, append some stuff
            apiData = complementWithMandatoryNewUohData(apiData);
          }

          // label specific stuff
          switch (label) {
            case 'uoh_str_your_reference':
              // possibly also set email
              if (viewData.uoh.uoh_str_your_reference && !viewData.uoh.uoh_str_email) {
                // look for email among yourReferences
                for (let yourReference of yourReferences.value) {
                  if (yourReference.text === viewData.uoh.uoh_str_your_reference.text && yourReference.email) {
                    viewData.uoh.uoh_str_email = yourReference.email;
                    apiData.fields.push({
                      field: 'uoh_str_email',
                      value: yourReference.email,
                    });
                    break;
                  }
                }
              }
              break;
          }

          showMessage('Sparar...');

          callApi({
            method: 'post',
            path: '/cms/orders/unconcluded-order-field',
            data: apiData,
          })
            .then(apiResponse => {
              closeMessage();
              onData(apiResponse);
            })
            .catch((err) => {
              closeMessage();
              console.error(err);
              // showMessage(err, null, 'onFieldEdit uoh');
            });
        break;
        case 'orderRow':
          apiData = {
            uorId: data.row_id,
            field: label,
            value,
          }

          setOverlay(true, 'Sparar inmatning');

          callApi({
            method: 'post',
            path: '/cms/orders/unconcluded-order-row',
            data: apiData,
          })
            .then(() => {
              setOverlay();
            })
            .catch((err) => {
              setOverlay();
              console.error(err);
              // showMessage(err, null, 'onFieldEdit uor');
            });
        break;
      }
		}

    const resetAlert = () => {
      alertHeadline.value = '';
      alertMessage.value = '';
    }

    const requestToPutOrder = async () => {
      // first sleep in order to allow possible just blurred field to trigger api save
      console.log("requestToPutOrder", "sleep");
      await delay(250);
      if (saveInProgress.value) {
        // try again in a while
        console.log("requestToPutOrder", "recall");
        requestToPutOrder();
      } else {
        // ok to put order
        console.log("requestToPutOrder", "ok");
        putOrder();
      }

    }

    const putOrder = () => {
      const errors = [];
      const formIsValid = form.value.validate();
      if (!formIsValid) {
        errors.push('Fyll i alla obligatoriska fält');
      }
      if (!customerId.value) {
        errors.push('En kund måste väljas');
      }
      if (!viewData.uoh.uoh_id_dmt) {
        errors.push('Leveranssätt måste väljas');
      }
      if (!viewData.uoh.uoh_enm_accounting_method) {
        errors.push('Bokföringsmetod måste väljas');
      }
      if (hasModule_fortnoxNodeJsTransferOrder.value) {
        const uohField_orgNo = 'uoh_str_invoice_vat_no';
        const uohField_fortnoxNo = 'uoh_str_customer_number';
        const placeholderField_orgNo = contactFields.find(item => item.uohField === uohField_orgNo).placeholderField;
        const placeholderField_fortnoxNo = contactFields.find(item => item.uohField === uohField_fortnoxNo).placeholderField;
        const has_orgNo = viewData.uoh[uohField_orgNo] || viewData.uoh[placeholderField_orgNo];
        const has_fortnoxNo = viewData.uoh[uohField_fortnoxNo] || viewData.uoh[placeholderField_fortnoxNo];
        if (!has_orgNo && !has_fortnoxNo) {
          errors.push('Organisations- eller Fortnox-nummer måste anges');
        }
      }
      if (errors.length) {
        showMessage(parseMessages(errors));
        return;
      }
      const apiData = {
        uohId: uohId.value,
        avoidCreateFortnoxOrder: avoidCreateFortnoxOrder.value,
      }
      setOverlay(true, 'Lägger order');
      callApi({
        method: 'put',
        path: '/cms/orders/put-order',
        data: apiData,
      })
        .then(() => {
          setOverlay();
          if (!store.getters.isFullLayoutMode) {
            tellKobra('on-put-order');
          } else {
            goToDashboard();
          }
        })
        .catch((err) => {
          setOverlay();
          console.error(err);
        });
    }

    const goToDashboard = () => {
      router.replace({ name: 'orderDashboard' });
    }

    const onFieldFocus = (label, value) => {
      focusedFieldValue.value = value;
    }

    const onData = (response) => {
      if (response.countries) {
        countries.value = response.countries;
      }
      if (response.yourReferences) {
        yourReferences.value = response.yourReferences;
      }
      if (response.ourReferences) {
        ourReferences.value = response.ourReferences;
      }
      if (response.uohId) {
        // on field edit a possibly uoh can have been created
        viewData.uoh.uoh_id = response.uohId;
      }
      if (response.importantCustomerNote) {
        // alert important customer note
        if (response.importantCustomerNote.has) {
          alertHeadline.value = response.importantCustomerNote.headline;
          alertMessage.value = convertJsonToHtml(response.importantCustomerNote.text);
        } else {
          resetAlert();
        }
      }
      if (response.uoh) {
        viewData.uoh = response.uoh;
        customerId.value = response.uoh.customerId;
        cgrId.value = response.uoh.uoh_id_cgr;
        // set profile display name in autocomplete component in a while, in order for it to work
        if (response.uoh.customerName) {
          setTimeout(function() {
            selectedCustomer.value = {
              l: response.uoh.customerName,
              v: customerId.value,
            }
          }, 300);
        }
      }
      if (response.uors) {
        viewData.uors = response.uors;
      }
      if (response.uorId) {
        orderRowIdToFocus.value = response.uorId;
          // we have to switch back in order for the prop to retrigger if same id again
          setTimeout(() => {
            orderRowIdToFocus.value = 0;
        }, 1000);
      }
      if (response.deliveryMethods) {
        deliveryMethods.value = response.deliveryMethods;
        if (!viewData.uoh.uoh_id_dmt) {
          // possible precheck default
          const dmtIndex_default = deliveryMethods.value.findIndex(element => element.f_default == 1);
          if (dmtIndex_default != -1) {
            viewData.uoh.uoh_id_dmt = deliveryMethods.value[dmtIndex_default].value;
          }
        }
      }
      if (response.stockPoints) {
        stockPoints.value = response.stockPoints;
      }

      if (response.customerSearchConfig) {
        customerSearchConfig.value = response.customerSearchConfig;
      }
      if (response.searchableProductData) {
        searchableProduct_labelCols.value = response.searchableProductData.labelCols;
        searchableProduct_items.value = response.searchableProductData.items;
      }
      dataLoaded.value = true;
    }

    const onUorDrag = (orderRows) => {
      viewData.uors = orderRows;
      const uorIds = viewData.uors.map(uor => uor.row_id);

      const apiData = {
        uorIds
      }

      setOverlay(true, 'Sparar ordning');

      callApi({
        method: 'post',
        path: '/cms/orders/unconcluded-order-rank',
        data: apiData,
      })
        .then(() => {
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          console.error(err);
          // showMessage(err, null, onUorDrag);
        });
    }

    const addUorText = text => {
      const apiData = {
        uor: {
          text,
        }
      }
      saveUnconcludedOrder(apiData);
    }

    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: '/cms/orders/unconcluded-order/0',
      })
        .then((apiResponse) => {
          onData(apiResponse);
        })
        .catch((err) => {
          console.error(err);
          // showMessage(err, null, 'doOnMounted');
        });
    }

    onMounted(doOnMounted);

    return {
      onProductSelect,
      onProductBarcodeScan,
      onCustomerSelect,
      onCustomerClear,
      doProfileAutoCompleteFocus,
      dataLoaded,
      viewData,
      selectedCustomer,
      countries,
      ourReferences,
      yourReferences,
      deliveryMethods,
      stockPoints,
      onFieldEdit,
      onFieldFocus,
      invoiceFields,
      shippingFields,
      removeOrderRow,
      saveInProgress,
      toolbarButtons,
      onToolbarButton,
      rules,
      form,
      restoreNumeric,
      contactFields,
      priceTypes,
      accountingMethods,
      hasUoh,
      goToDashboard,
      headline,
      onUorDrag,
      addUorText,
      initiallyOpenedExpansionPanels,
      customerSearchConfig,
      hasModule_fortnoxNodeJsTransferOrder,
      searchableProduct_labelCols,
      searchableProduct_items,
      alertHeadline,
      alertMessage,
      resetAlert,
      orderRowIdToFocus,
      priceTypeIsNetto,
      isKaxig,
      avoidCreateFortnoxOrder,
    }
  },
};
</script>

<style>
.v-data-table {
  background-color: transparent !important;
}
/* in order to have autocomplete suggestion div visible when inside table */
.v-data-table__wrapper {
  overflow: visible !important;
}
.vx-group-column div.v-slide-group__content {
  flex-direction: column !important;
}
</style>